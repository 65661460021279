// @import packages
import React from 'react';
import { useCookies } from 'react-cookie';
import { Route, Redirect, withRouter, Switch } from 'react-router-dom';
import Login from 'sections/Login';
import Layout from 'components/layout';
// @import styles
import './App.scss';

const App = props => {
	const { routes, location = { pathname: '/', key: '' } } = props;
	const [cookies] = useCookies(['authToken']);
	return (
		<Layout>
			{cookies.authToken ? (
				<Switch location={location}>
					{routes.map((route, index) => {
						return (
							<Route
								key={index}
								path={route.path}
								exact={route.exact}
								render={props => {
									return React.createElement(route.component, {
										...props
									});
								}}
							/>
						);
					})}
					<Redirect from="*" to="/404" />
				</Switch>
			) : (
				<Login />
			)}
		</Layout>
	);
};

export default withRouter(App);
