import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
// @import styles
import 'css/components/apiModule.scss';

const auto_grow = element => {
	element.style.height = element.scrollHeight + 2 + 'px';
};

const ApiModule = ({
	requestTitle,
	requestType,
	schema,
	token = false,
	endpoint
}) => {
	const [schemaValue, setSchemaValue] = useState(
		JSON.stringify(schema, null, '\t')
	);
	const [tokenValue, setTokenValue] = useState('');
	const [isPlayGround, setPlayGround] = useState(false);
	const [response, setResponse] = useState({
		data: null,
		status: null
	});
	const textRef = useRef(null);

	useEffect(() => {
		auto_grow(textRef.current);
	}, []);

	const handleChange = e => {
		setSchemaValue(e.target.value);
	};

	const handleExecute = () => {
		axios({
			method: requestType,
			url: endpoint,
			data: JSON.parse(schemaValue),
			headers: {
				Authorization: `Bearer ${tokenValue}`
			}
		})
			.then(response => {
				setResponse({
					data: response.data,
					status: response.status
				});
			})
			.catch(error => {
				setResponse({
					data: error.response.data,
					status: error.response.status
				});
			});
	};

	return (
		<div className="moduleWrapper">
			<h2>{requestTitle}</h2>
			<div className="flexBetween accordionWrapper">
				<div className="flexCenter">
					<span className="requestType">{requestType}</span>
					<span className="requestUrl">{endpoint}</span>
				</div>
				<button
					type="button"
					className={isPlayGround ? 'cancelBtn' : 'tryoutBtn'}
					onClick={() => {
						setPlayGround(!isPlayGround);
						setTimeout(() => {
							auto_grow(textRef.current);
						}, 50);
					}}
				>
					{isPlayGround ? 'Cancel' : 'Try it out'}
				</button>
			</div>
			<div
				className="schemaWrapper"
				style={{ display: isPlayGround ? 'none' : 'block' }}
			>
				<div className="flexBetween">
					<span>
						<b>Request body | Schema</b>
					</span>
					<span>application/json</span>
				</div>
				<pre className="schemaPreview">
					{JSON.stringify(schema, null, '\t')}
				</pre>
			</div>

			<div
				className="playGround"
				style={{ display: isPlayGround ? 'block' : 'none' }}
			>
				{token && (
					<>
						<div className="flexBetween">
							<span>
								<b>Request header</b>
							</span>
							<span>Authorization</span>
						</div>
						<div className="headerToken">
							<span>Bearer</span>
							<input
								type="text"
								value={tokenValue}
								onChange={e => setTokenValue(e.target.value)}
							/>
						</div>
					</>
				)}
				<div className="flexBetween">
					<span>
						<b>Request body | Schema</b>
					</span>
					<span>application/json</span>
				</div>
				<textarea
					ref={textRef}
					spellCheck="false"
					value={schemaValue}
					onChange={handleChange}
					className="schemaPreview"
				/>
				<button
					type="button"
					className="tryoutBtn executeBtn"
					onClick={handleExecute}
				>
					Execute
				</button>

				<div className="flexBetween">
					<span>
						<b>Response</b>
					</span>
					<span>
						<b>status: </b>
						{response.status}
					</span>
				</div>
				<pre className="responsePreview">
					{response.data && JSON.stringify(response.data, null, '\t')}
				</pre>
			</div>
		</div>
	);
};

export default ApiModule;
