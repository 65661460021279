import React from 'react';
import { Helmet } from 'react-helmet';
// @import sections
import Overview from '../sections/Overview';

const Home = () => {
	return (
		<div>
			<Helmet>
				<meta
					name="description"
					content="Cheqi is de leukste manier om geld kado te doen of kleine bedragen te betalen"
				/>
			</Helmet>
			<Overview />
		</div>
	);
};

export default Home;
