import React from 'react';
import { Helmet } from 'react-helmet';
// @import constants
import { partner_login, partner_createorder } from 'constants/endpoints';
// @import components
import ApiModule from 'components/apiModule';

const Api = () => {
	return (
		<div className="contentWrapper contentMargin">
			<Helmet>
				<meta
					name="description"
					content="Cheqi is de leukste manier om geld kado te doen of kleine bedragen te betalen"
				/>
			</Helmet>
			<h1>API documentatie voor developers</h1>
			<p>
				Je koppelt de Cheqi API makkelijk aan jullie platform. Dat doe je zo.
			</p>

			<ApiModule
				requestType="post"
				requestTitle="Accounts"
				endpoint={partner_login}
				schema={{
					username: 'string',
					password: 'string'
				}}
			/>

			<ApiModule
				token
				requestType="post"
				requestTitle="Create Cheqi"
				endpoint={partner_createorder}
				schema={{
					partnerReference: 'string',
					senderEmail: 'string',
					senderFirstName: 'string',
					cheqiAmount: 0,
					receiverEmail: 'string',
					receiverFirstName: 'string',
					receiverMessage: 'string'
				}}
			/>

			<h3>Vragen</h3>
			<p>
				Heb je vragen over het gebruik van de API? Neem dan contact op met je
				Cheqi Partner Manager via het volgende e-mailadres{' '}
				<a href="mailto:partners@cheqi.nl">partners@cheqi.nl</a>.
			</p>
		</div>
	);
};

export default Api;
