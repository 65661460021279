import React from 'react';
import { NavLink } from 'react-router-dom';
import routes from '../../routes';
// @import styles
import '../../css/components/footer.scss';

const Footer = () => {
	return (
		<>
			<span className="seprator" />
			<div className="copyrights">
				<div className="gridColumn">
					<div>
						{routes
							.filter(item => item.isInFooter)
							.map(route =>
								route.isExrernal ? (
									<a
										key={route.name}
										href={route.path}
										target="_blank"
										rel="noopener noreferrer"
									>
										{route.name}
									</a>
								) : (
									<NavLink
										activeClassName="active"
										to={route.path}
										key={route.name}
										exact
									>
										{route.name}
									</NavLink>
								)
							)}
						<a href="mailto:partners@cheqi.nl">Ondersteuning voor partners</a>
					</div>
					<span>Copyright &copy; 2021 Cheqi B.V.</span>
				</div>
			</div>
		</>
	);
};

export default Footer;
