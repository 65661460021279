import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
// @import components
import Logo from '../logo';
import Menu from '../navMenu';
// @import styles
import 'css/components/header.scss';
// @import images
import { ReactComponent as Back } from 'svg/cross.svg';

const Header = ({ location }) => {
	const [isSticky, setSticky] = useState(false);
	const ref = useRef(null);
	let elem;

	const handleScroll = () => {
		if (!elem) {
			elem = document.querySelector('.mainContainer');
		}
		let timeoutId = null;
		clearTimeout(timeoutId);
		timeoutId = setTimeout(
			() => setSticky(elem.getBoundingClientRect().top <= -50),
			150
		);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', () => handleScroll);
		};
	});

	return (
		<header ref={ref} className={`header ${isSticky ? 'active' : ''}`}>
			<div className="gridColumn">
				<Logo />
				{location !== '/' && (
					<Link className="backarrow" to="/">
						<Back />
					</Link>
				)}
				<Menu />
			</div>
		</header>
	);
};

export default Header;
