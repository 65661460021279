import React from 'react';
import { Link } from 'react-router-dom';
// @import styles
import '../css/components/logo.scss';
// @import logo svg
import { ReactComponent as CheqiLogo } from '../svg/logo.svg';

const Logo = () => {
	return (
		<Link to="/" className="logoWrapper">
			<CheqiLogo className="logo" />
		</Link>
	);
};

export default Logo;
