let apiurl;
let redirectUrl;

switch (window.location.hostname) {
	case 'localhost':
		apiurl = 'https://cheqiapiacc.azurewebsites.net/api/';
		redirectUrl = 'https://accept.cheqi.nl/';
		break;
	case 'partners.accept.cheqi.nl':
		apiurl = 'https://cheqiapiacc.azurewebsites.net/api/';
		redirectUrl = 'https://partners.accept.cheqi.nl';
		break;
	default:
		apiurl = 'https://api.cheqi.nl/api/';
		redirectUrl = 'https://www.cheqi.nl/';
}

export const redirect_url = redirectUrl;
export const api_url = apiurl;
export const cors_url = 'https://cors-anywhere.herokuapp.com/';
// export const api_url = `${cors_url}${apiurl}`;

// endpoints
export const partner_login = `${api_url}Account/authenticate`;
export const partner_createorder = `${api_url}PartnerOrder`;
export const partner_orders = `${api_url}PartnerOrder/getPartnerOrders`;
