import React from 'react';
import { Helmet } from 'react-helmet';

const PageNotFound = () => {
	return (
		<div className="contentWrapper notFound">
			<Helmet>
				<meta
					name="description"
					content="Je kunt Cheqi gebruiken om geld kado te geven. Of om kleine bedragen te betalen. Via bijvoorbeeld WhatsApp, Instagram, Facebook, TikTok of e-mail."
				/>
			</Helmet>
			<h1>404</h1>
			<p>Page not found</p>
		</div>
	);
};

export default PageNotFound;
