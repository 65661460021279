import React, { useRef } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useHistory } from 'react-router-dom';
// @import routes
import routes from '../routes';
// @import utils
import { useMenuActive } from '../utils/handleMenuActive';
// @import styles
import 'css/components/navMenu.scss';

const Item = ({ name, path, active }) => {
	const ref = useRef();
	useMenuActive(ref);
	return (
		<Link ref={ref} to={path}>
			<span className={active ? 'active' : ''}>{name}</span>
		</Link>
	);
};

const NavMenu = () => {
	const history = useHistory();
	// eslint-disable-next-line no-unused-vars
	const [cookies, setCookie, removeCookie] = useCookies(['authToken']);

	const handleLogout = () => {
		let expDate = new Date();
		removeCookie('authToken', {
			// domain: 'onderbouwdonline.nl',
			expires: expDate,
			path: '/'
		});
		history.push('/');
	};

	return (
		<nav className="headerMenu">
			<ul>
				{cookies.authToken ? (
					<>
						{routes
							.filter(item => item.isInHeader)
							.map((item, index) => (
								<li key={`route-${index}`}>
									<Item
										path={item.path}
										active={history.location.pathname === item.path}
										name={item.name}
									/>
								</li>
							))}
						<li>
							<span onClick={handleLogout}>uitloggen</span>
						</li>
					</>
				) : (
					<li>
						<a href="http://cheqi.nl">naar cheqi.nl</a>
					</li>
				)}
			</ul>
		</nav>
	);
};

export default NavMenu;
