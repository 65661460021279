import React from 'react';
import { Helmet } from 'react-helmet';

const Privacy = () => {
	return (
		<div className="contentWrapper contentMargin">
			<Helmet>
				<meta
					name="description"
					content="Dit is de privacyverklaring van Cheqi. Hierin leggen we uit wat we doen met je persoonsgegevens."
				/>
			</Helmet>
			<h1>Cheqi privacyverklaring</h1>
			<p>
				Dit is de privacyverklaring van Cheqi. Hierin leggen we uit wat we doen
				met je persoonsgegevens.
			</p>
			<ol type="1">
				<li>
					We gebruiken de volgende woorden met een hoofdletter:
					<ol type="a">
						<li>Cheqi: dat zijn de Cheqi-app en Cheqi-website.</li>
						<li>
							Cheqi-betaling: een klein bedrag betalen via iDeal met Cheqi.
						</li>
						<li>
							Betaaldienstverlener: Cheqi maakt gebruik van de betaaldiensten
							van Online Betaalplatform.
						</li>
					</ol>
				</li>
				<li>
					Je mag Cheqi alleen gebruiken als je akkoord gaat met deze
					privacyverklaring. Dit akkoord moet je geven in Cheqi, voor het eerste
					gebruik.
				</li>
				<li>
					Cheqi ontvangt en gebruikt persoonsgegevens van je. Dat gebeurt pas
					als je een Cheqi-betaling gaat doen. We ontvangen alleen de voornaam
					van de ontvanger van de Cheqi-betaling. De Betaaldienstverlener
					ontvangt het bankrekeningnummer van de ontvanger en de naam waarop de
					bankrekening staat.
				</li>
				<li>
					We koppelen verder een uniek nummer aan jouw gebruik van Cheqi.
					Anoniem, dus we weten niet wie je bent, totdat je een Cheqi-betaling
					gaat doen.
				</li>
				<li>
					Je kan een Cheqi-betaling op verschillende manieren delen.
					Bijvoorbeeld via WhatsApp, Instragram of TikTok. Hierop kunnen andere
					voorwaarden van toepassing zijn, ook over het gebruik van je
					persoonsgegevens. Cheqi is hier niet bij betrokken, je moet dus zelf
					goed kijken wat je deelt.
				</li>
				<li>
					Het doel waarvoor Cheqi deze persoonsgegevens nodig heeft, is het
					uitvoeren van de overeenkomst met Cheqi. Er kan ook sprake zijn van
					een wettelijke plicht om gegevens te bewaren en te gebruiken.
				</li>
				<li>
					Je kan altijd een overzicht opvragen van de persoonsgegevens die we
					van je hebben in Cheqi. Neem daarvoor contact met ons op, je vindt de
					contactgegevens op de website van Cheqi.
				</li>
				<li>
					We bewaren je persoonsgegevens niet langer dan nodig is voor het doel.
					Dus in de eerste plaats voor onze overeenkomst, zolang die er is. We
					bewaren gegevens voor de wettelijke termijn zolang dat moet.
				</li>
				<li>
					Je kan ons altijd vragen om je persoonsgegevens te wissen. We doen dat
					graag voor je. Persoonsgegevens die we bewaren door een wettelijke
					plicht mogen we niet wissen. Cheqi verwijderen betekent ook niet dat
					je persoonsgegevens en betaalgeschiedenis uit onze systemen zijn. Als
					je dat wilt moet je contact opnemen.
				</li>
				<li>
					Je kan ons vragen om je persoonsgegevens door te geven aan een ander
					bedrijf. Dat heet dataportabiliteit. Neem contact met ons op als je
					dat wilt.
				</li>
				<li>
					Soms geven we je persoonsgegevens door aan bedrijven die ons helpen
					onze overeenkomst. Dat zijn in ieder geval bedrijven die
					betalingsdiensten verzorgen en een externe helpdesk. We gaan altijd
					een verwerkersovereenkomst aan, als dat wettelijk is verplicht. Cheqi
					is in de eerste plaats verantwoordelijk(e) voor jouw persoonsgegevens.
				</li>
				<li>
					Vragen over het gebruik van persoonsgegevens door Cheqi of klachten
					kan je mailen aan{' '}
					<a href="mailto: privacy@cheqi.nl">privacy@cheqi.nl</a>
				</li>
				<li>
					Als je vindt dat we niet goed omgaan met je persoonsgegevens kan je
					ook altijd een klacht indienen bij de Autoriteit Persoonsgegevens.
				</li>
				<li>
					Cheqi gebruikt trackingcookies om het gebruik te analyseren. Dit is
					steeds anoniem, dus we weten niet om wie het gaat. We plaatsen deze
					cookies alleen in Cheqi en op de Cheqi-website.
				</li>
				<li>
					Deze Cheqi privacyverklaring geldt vanaf februari 2020. We kunnen deze
					Cheqi privacyverklaring wijzigen. Je moet de nieuwe Cheqi
					privacyverklaring dan weer accepteren. Je ziet dat dan in Cheqi.
				</li>
			</ol>
		</div>
	);
};

export default Privacy;
