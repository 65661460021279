import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
// @import components
import Spinner from 'components/Spinner';
import TableGrid from 'components/tableGrid';
// @import styles
import 'css/sections/overview.scss';
// @import constants
import { partner_orders } from 'constants/endpoints';

const Overview = () => {
	const [cookies] = useCookies(['authToken']);

	const [loading, setLoading] = useState(false);
	const [data, setdata] = useState({
		columnTitles: [
			{
				text: 'Datum',
				toolTip: 'Het tijdstip waarop het Cheqi is aangemaakt'
			},
			{
				text: 'E-mail',
				toolTip: 'Het e-mailadres van de verzender van het Cheqi'
			},
			{
				text: 'Bedrag',
				toolTip: 'Het bedrag van het Cheqi'
			},
			{
				text: 'Identificate',
				toolTip: 'De ontvanger heeft zich geidentificeerd'
			},
			{
				text: 'Betaling',
				toolTip:
					'De verzender heeft het bedrag van het Cheqi daadwerkelijk betaald'
			},
			{
				text: 'Referentle',
				toolTip: 'Referentie van jullie platform'
			},
			{
				text: 'Cheqi ID',
				toolTip: 'Uniek ID, gebruik dit bij eventuele vragen'
			}
		],
		rows: null
	});

	useEffect(() => {
		setLoading(true);
		axios
			.get(partner_orders, {
				headers: {
					Authorization: `Bearer ${cookies.authToken}`
				}
			})
			.then(res => {
				setLoading(false);
				setdata({ ...data, rows: res.data });
			})
			.catch(error => {
				console.error(error);
			});
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="overviewWrapper">
			<h1>Overzicht Cheqi's</h1>
			<p>Deze Cheqi's zijn via jullie platform aangemaakt.</p>
			{data.rows && <TableGrid data={data} />}
			{loading && (
				<span className="spinnerWrapper">
					<Spinner theme="dark" />
				</span>
			)}
		</div>
	);
};

export default Overview;
