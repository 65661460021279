import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
// @import components
import Spinner from 'components/Spinner';
// @import styles
import 'css/sections/login.scss';
// @import constants
import { partner_login } from 'constants/endpoints';
// @import images
import pattern from 'images/mobilepattern.png';

const Login = () => {
	const history = useHistory();
	// eslint-disable-next-line no-unused-vars
	const [cookies, setCookie] = useCookies(['authToken']);
	const [loading, setLoading] = useState(false);

	const [username, setUsername] = useState({ value: '' });
	const [password, setPassword] = useState({ value: '' });
	const [error, setError] = useState(null);

	const handleSubmit = e => {
		e.preventDefault();
		if (username.value.length < 3) {
			setUsername({ ...username, error: true });
			return;
		}
		if (password.value.length < 3) {
			setPassword({ ...password, error: true });
			return;
		}

		if (!username.value?.error && !password.value?.error) {
			setLoading(true);
			axios
				.post(partner_login, {
					username: username.value,
					password: password.value
				})
				.then(
					response => {
						setLoading(false);
						var expDate = new Date();
						expDate.setTime(expDate.getTime() + 60 * 60 * 1000);
						setCookie('authToken', response.data.token, {
							// domain: 'onderbouwdonline.nl',
							expires: expDate
						});
						history.go(0);
					},
					error => {
						setLoading(false);
						setError(error.response.data.message);
					}
				);
		}
	};

	const changePassword = () => {
		document.getElementById('password').setAttribute('type', 'password');
	};

	const usernameHandler = e => {
		const error = e.target.value.length < 3;
		setUsername({ value: e.target.value, error });
	};

	const passwordHandler = e => {
		const error = e.target.value.length < 3;
		setPassword({ value: e.target.value, error });
	};

	return (
		<div className="contentWrapper">
			<Helmet>
				<meta
					name="description"
					content="Cheqi is de leukste manier om geld kado te doen of kleine bedragen te betalen"
				/>
			</Helmet>
			<div className="flexCenter loginWrapper">
				<h1>Cheqi partner dashboard</h1>
				<p>
					Met een Cheqi biedt je jouw klanten de mogelijkheid om een geldbedrag
					mee te sturen met jouw product. Zoals een cadeaubon, maar dan met geld
					dat overal te besteden is. Zo maak je het je klanten nog makkelijker
					om iemand blij te maken.
				</p>
				<p>
					Log hieronder in met de door de Cheqi Partner Manager verstrekte
					logingegevens.
				</p>
				<h3>Inloggen</h3>
				<form onSubmit={handleSubmit} className="loginForm">
					<div className="row">
						<label htmlFor="username" className="label">
							Gebruikersnaam
						</label>
						<input
							id="username"
							className={username.error ? 'error' : ''}
							onChange={usernameHandler}
							onClick={changePassword}
							type="text"
						></input>
					</div>
					<div className="row">
						<label htmlFor="password" className="label">
							Wachtwoord
						</label>
						<input
							id="password"
							className={password.error ? 'error' : ''}
							onChange={passwordHandler}
							onClick={changePassword}
							type="text"
						></input>
					</div>
					<div className="row buttonsRow">
						<a
							className="mailLink"
							href="mailto:partners@cheqi.nl?subject=Partner wachtwoord"
						>
							Wachtwoord vergeten
						</a>
						<button
							type="submit"
							disabled={loading}
							className="loginBtn"
							onClick={handleSubmit}
						>
							Login
						</button>
					</div>
					{error && (
						<div className="row errorWrapper">
							<p>{error}</p>
						</div>
					)}
					{loading && (
						<div className="spinnerWrapper">
							<Spinner />
						</div>
					)}
				</form>
			</div>
			<div
				className="patternWrapper"
				style={{ backgroundImage: `url(${pattern})` }}
			></div>
		</div>
	);
};

export default Login;
