import React, { useState } from 'react';
import moment from 'moment';
import localization from 'moment/locale/nl';
// @import styles
import 'css/components/tableGrid.scss';
// @import svgs
import { ReactComponent as Tick } from 'svg/tick.svg';

const showToolTip = (e, index) => {
	const { top, left } = e.currentTarget.getBoundingClientRect();
	const elem = document.querySelector(`.tooltip-${index}`);
	elem.style.display = 'block';
	elem.style.top = `${top}px`;
	elem.style.left = `${left}px`;
};

const hideTooltip = index => {
	const elem = document.querySelector(`.tooltip-${index}`);
	elem.style.display = null;
};

const TableGrid = ({ data, rowsPerPage = 9 }) => {
	const [page, setPage] = useState(0);

	const dataRange = [page * rowsPerPage, page * rowsPerPage + rowsPerPage];
	const dataToShow = data?.rows?.slice(dataRange[0], dataRange[1]);

	const createPageButtons = () => {
		const totalPages = Math.ceil(data?.rows?.length / rowsPerPage);
		const pageButtons = [];
		for (let i = 0; i < totalPages; i++) {
			pageButtons.push(
				<button
					key={i}
					onClick={() => setPage(i)}
					className={`pageButton ${i === page ? 'active' : ''}`}
				>
					{i + 1}
				</button>
			);
		}
		return pageButtons.length > 1 ? pageButtons : null;
	};

	const pageButtons = createPageButtons();

	return (
		<div className="tableGrid">
			<table>
				<thead>
					<tr className="titlesRow">
						{data?.columnTitles?.map((title, index) => (
							<th key={`title-${index}`}>
								<span>{title.text}</span>
								<span
									onMouseOver={e => {
										showToolTip(e, index);
									}}
									onMouseOut={() => {
										hideTooltip(index);
									}}
									className="toolTip"
								>
									i
								</span>
								<span className={`toolTipText tooltip-${index}`}>
									{title.toolTip}
								</span>
							</th>
						))}
					</tr>
					<tr className="spacer"></tr>
				</thead>
				<tbody>
					{dataToShow.map((row, index) => (
						<tr key={`row-${index}`} className="row">
							<td className="rowItem">
								{moment(row.createdDate)
									.locale('nl', localization)
									.format('DD-MM-YYYY HH:MM:SS')}
							</td>
							<td className="rowItem">{row.senderEmail}</td>
							<td className="rowItem">€ {row.amount}</td>
							<td className="rowItem">
								{row.status > 0 ? <Tick /> : <span></span>}
							</td>
							<td className="rowItem">
								{row.status === 2 ? <Tick /> : <span></span>}
							</td>
							<td className="rowItem">{row.partnerReference}</td>
							<td className="rowItem">{row.orderId.substring(0, 8)}**</td>
						</tr>
					))}
				</tbody>
			</table>
			{pageButtons && (
				<div className="buttonsWrapper">
					<button
						onClick={() => {
							if (page > 0) setPage(page - 1);
						}}
						className="prevButton"
					>
						vorige
					</button>
					{pageButtons}
					<button
						onClick={() => {
							if (page < pageButtons.length - 1) setPage(page + 1);
						}}
						className="nextButton"
					>
						volgende
					</button>
				</div>
			)}
		</div>
	);
};

export default TableGrid;
