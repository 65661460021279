import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';

const Terms = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	return (
		<div className="contentWrapper contentMargin">
			<Helmet>
				<meta
					name="description"
					content="Dit zijn de spelregels voor het gebruik van Cheqi. Hierin leggen we uit hoe Cheqi werkt, wat wij doen, wat jij moet doen - en wat niet moet."
				/>
			</Helmet>
			<h1>Cheqi gebruiksvoorwaarden</h1>
			<p>
				Dit zijn de spelregels voor het gebruik van Cheqi. Hierin leggen we uit
				hoe Cheqi werkt, wat wij doen, wat jij moet doen - en wat niet moet.
			</p>
			<ol type="1">
				<li>
					We gebruiken de volgende woorden met een hoofdletter:
					<ol type="a">
						<li>Cheqi: dat zijn de Cheqi-app en Cheqi-website.</li>
						<li>
							Cheqi-betaling: een klein bedrag betalen via iDeal met Cheqi.
						</li>
					</ol>
				</li>
				<li>
					Je moet deze voorwaarden en de privacyverklaring accepteren als je
					Cheqi voor het eerst gebruikt. Je gaat dan een overeenkomst aan met
					Cheqi. Daarna kan je een Cheqi-betaling doen.
				</li>
				<li>
					Cheqi maakt gebruik van de betaaldiensten van Online Betaalplatform.
					Deze betaaldienst heeft een vergunning van De Nederlandse Bank. Je
					moet de voorwaarden van Online Betaalplatform ook accepteren, voordat
					je een Cheqi-betaling doet. Je vindt de voorwaarden van Online
					Betaalplatform in Cheqi.
				</li>
				<li>
					Een Cheqi-betaling doen werkt zo:
					<ol type="a">
						<li>Je vult de naam van de ontvanger van je Cheqi-betaling in.</li>
						<li>
							Je betaalt het door jou gekozen bedrag via iDeal: minimaal &euro;
							2, maximaal &euro; 100.
						</li>
						<li>Cheqi maakt een link aan en een bijbehorende unieke code.</li>
						<li>
							De link en de code deel je met de ontvanger, als het kan apart van
							elkaar.
						</li>
						<li>
							De ontvanger kan het bedrag dan op zijn eigen rekening laten
							overmaken.
						</li>
						<li>
							De ontvanger moet zich identificeren, voor de eerste ontvangst van
							een Cheqi-betaling. Dat is wettelijk verplicht. De ontvanger moet
							hiervoor één eurocent overmaken.
						</li>
					</ol>
				</li>
				<li>
					Het gebruik van Cheqi is - voorlopig - gratis! Dit kan in de toekomst
					veranderen. We denken bijvoorbeeld over uitbreiding van onze diensten.
					Wees gerust, je hoort het ruim van tevoren als het verandert.
				</li>
				<li>Je mag Cheqi alleen gebruiken als je meerderjarig bent.</li>
				<li>
					Je kan een Cheqi-betaling intrekken zolang het bedrag niet is
					opgehaald door de ontvanger. Daarna dus niet meer.
				</li>
				<li>
					Een Cheqi-betaling moet binnen 3 maanden worden opgehaald. Anders
					wordt het teruggestort op je bankrekening. Dat gaat automatisch, je
					hoeft daar niets voor te doen. Het bedrag dat je betaalt gaat dus naar
					de ontvanger en anders komt het terug bij jou.
				</li>
				<li>
					Je kan Cheqi natuurlijk verwijderen. Je bent dan wel je
					betaalgeschiedenis in Cheqi kwijt. Je kan Cheqi-betalingen altijd
					terugzien in je eigen bankrekening.
				</li>
				<li>
					Een ontvanger moet zich opnieuw identificeren, als hij of zij meer dan
					&euro; 1.500 zal ontvangen. Dat is wettelijk verplicht. Het moet door
					een foto van een geldig identiteitsbewijs op te sturen.
				</li>
				<li>
					We hebben Cheqi zo veilig als mogelijk gemaakt. Jij moet Cheqi ook zo
					veilig mogelijk gebruiken. De volgende tips moet je zeker opvolgen:
					<ol type="a">
						<li>
							Controleer altijd goed of je de iDeal-betaling echt aan Cheqi
							doet.
						</li>
						<li>
							De link en de code zijn persoonlijk, dus alleen voor jou en de
							ontvanger bedoeld. Je mag ze dus niet aan meerdere personen
							doorsturen.
						</li>
						<li>
							Je moet de link en de code alleen sturen aan de ontvanger, verder
							aan niemand anders. Ook niet aan bankmedewerkers of medewerkers
							van Cheqi.
						</li>
						<li>
							Annuleer een Cheqi-betaling meteen als iemand anders dan de
							ontvanger de link en de code heeft gezien of gekopieerd. Je kan
							altijd een nieuwe Cheqi-betaling doen.
						</li>
						<li>
							Anderen mogen Cheqi op jouw telefoon niet gebruiken. Ook niet met
							hun eigen bankrekening. Cheqi is er alleen voor jou.
						</li>
						<li>
							Beveilig je mobiele telefoon. Gebruik een toegangscode,
							vingerafdruk of gezichtsherkenning.
						</li>
						<li>
							Controleer je bankrekening als je Cheqi-betaling is opgehaald.
							Kijk of het goede bedrag is overgemaakt, naar de juiste ontvanger.
							Meld het meteen aan je bank als iets niet goed is gegaan.
						</li>
						<li>
							Meldt betalingen die je niet hebt gedaan meteen bij je bank.
						</li>
					</ol>
				</li>
				<li>
					Cheqi vergoedt geen schade bij oplichting, misbruik, storing en
					dergelijke. Verkeerd gebruik is je eigen schuld. Goed opletten dus!
				</li>
				<li>
					Wij kunnen Cheqi blokkeren bij misbruik, ook als dat zo lijkt. Wij
					kunnen je toegang tot Cheqi ook beëindigen, als je er heel lang niets
					mee doet. Dat kan ook in verband met de veiligheid - van jou en van
					Cheqi.
				</li>
				<li>
					Zorg ervoor dat je steeds de nieuwste versie van Cheqi gebruikt. Check
					regelmatig of er updates zijn.
				</li>
				<li>
					Vragen over Cheqi kan je stellen per e-mail. De contactgegevens staan
					op de website van Cheqi.
				</li>
				<li>
					Deze Cheqi voorwaarden gelden vanaf februari 2020. We kunnen de Cheqi
					voorwaarden wijzigen. Je moet de nieuwe Cheqi voorwaarden dan weer
					accepteren. Je ziet dat dan in Cheqi.
				</li>
				<li>
					Er zijn voorwaarden van toepassing op het gebruik van iDeal. Je kan
					die nalezen bij je eigen bank.
				</li>
				<li>
					Cheqi heeft toegang tot bepaalde persoonsgegevens. Wat, waarom, hoe
					lang en je rechten staan allemaal in de{' '}
					<Link
						to={`${
							searchParams.get('mode') === 'webview'
								? '/privacyverklaring?mode=webview'
								: '/privacyverklaring'
						}`}
					>
						Cheqi privacyverklaring
					</Link>
					.
				</li>
				<li>
					Het Nederlands recht is van toepassing op deze Cheqi voorwaarden.
				</li>
				<li>
					De Cheqi app is ontwikkeld door Cheqi B.V., gevestigd te Amersfoort,
					Kamer van Koophandel nummer 7523 0097. Alle intellectuele
					eigendomsrechten zijn van Cheqi B.V.
				</li>
			</ol>
		</div>
	);
};

export default Terms;
