import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function usePageChange() {
	let location = useLocation();
	useEffect(() => {
		// ga.send(['pageview', location.pathname]);
		if (location.pathname !== '/') {
			document.querySelector('body').scrollTo(0, 0);
		}
	}, [location]);
}
