import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import routes from './routes';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<Router>
		<App routes={routes} />
	</Router>,
	document.getElementById('root')
);

serviceWorker.unregister();
