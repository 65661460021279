import Home from './pages/Home';
import Terms from './pages/Terms';
import Api from './pages/Api';
import Privacy from './pages/Privacy';
import PageNotFound from './pages/PageNotFound';

const routes = [
	{
		path: '/',
		exact: true,
		name: 'overzicht',
		component: Home,
		isInHeader: true
	},
	/*{
		path: '/maak-een-cheqi',
		name: 'maak een cheqi',
		component: Privacy,
		isInHeader: true
	},*/
	{
		path: '/api',
		name: 'developers',
		component: Api,
		isInHeader: true
	},
	{
		path: '/gebruiksvoorwaarden',
		name: 'Gebruiksvoorwaarden',
		exact: true,
		isInFooter: true,
		component: Terms
	},
	{
		path: '/privacyverklaring',
		name: 'Privacyverklaring',
		exact: true,
		isInFooter: true,
		component: Privacy
	},
	{
		path: '/404',
		component: PageNotFound
	}
];

export default routes;
